<template>
  <div class="px-2">
    <LoansTable :tab="tab" :status="2" />
  </div>
</template>

<script>
import LoansTable from "@/views/commons/components/loans/LoansTable.vue";
export default {
  name: "LoansManagementExternal",
  components: {
    LoansTable,
  },
  data() {
    return {
      tab: this.$route.meta.tab,
    };
  },
};
</script>

<style>
</style>